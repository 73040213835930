

































































































































































































































































import {ModelFbx, ModelObj} from 'vue-3d-model';
import {Component} from 'vue-property-decorator'
import config from '@/config'
import {deleteOSSFile} from "@/utils/oss";
import {apiCreateTopic, apiEditTopic} from '@/api/goods';
import {goBackConfirm} from "@/utils/confirm";
import BtnSingleUploader from "@/components/upload/BtnSingleUploader.vue";
import {mixins} from 'vue-class-component';
import BeforeUploadMixins from "@/mixin/before-uploader";
import DialogUploader from "@/components/upload/DialogUploader.vue";

@Component({
  components: {BtnSingleUploader, DialogUploader, ModelObj, ModelFbx},
})
export default class MaterialManage extends mixins(BeforeUploadMixins) {
  $refs!: { baseForm: any }
  action = `${config.baseURL}/adminapi/upload/lightFile`;
  version = config.version;
  options: any = [{
    value: 1,
    label: '画册模式'
  }, {
    value: 2,
    label: '台历模式'
  }, {
    value: 3,
    label: '音箱模式'
  }];
  
  value = 0;
  baseForm: any = {
    tableData: []
  }
  rules: any = {
    name: [
      {required: true, message: '请输入主题中文名', rigger: ['blur', 'change']}
    ],
    name_en: [
      {required: true, message: '请输入主题英文名', rigger: ['blur', 'change']},
      {pattern: /^[A-Za-z]+$/, message: '仅支持英文字母', trigger: ['blur', 'change']}
    ],
    from_origin_topic_name: [
      {required: true, message: '请输入主题来源', rigger: ['blur', 'change']}
    ],
    // note: [
    //   {required: true, message: '请输入主题描述', trigger: 'blur'}
    // ],
    // url: [
    //   {required: true, message: '请上传封面图', trigger: ['blur', 'change']}
    // ],
    // models: [
    //   {required: true, message: '请上传光场3D', trigger: ['blur', 'change']}
    // ],
    // mobile_models: [
    //   {required: true, message: '请上传手机端模型', trigger: ['blur', 'change']}
    // ]
  }

  reUpload(type: number, index: number) {
    this.$confirm('确定删除吗？', '删除提示').then(() => {
      switch (type) {
        case 1:
          this.baseForm.tableData[index].goods_light_uri = '';
          break;
        case 2:
          this.baseForm.tableData[index].models = '';
          break;
        case 3:
          this.baseForm.tableData[index].mobile_models = '';
          break;
      }
    }).catch(() => {
    })
  }

  // 保存
  async createTopic() {
    console.log(this.baseForm.tableData)
    // 如果没有添加数据，就提示
    if (this.baseForm.tableData.length === 0) return this.$message.error('请添加数据！')
    // 如果有数据，便验证
    this.$refs.baseForm.validate((valid: boolean) => {
      // 验证不通过不继续
      if (!valid) return
      this.$confirm('确定保存当前内容吗？', '保存提示').then(async () => {
        // 判断当前是否是修改
        if (this.$route.params.data) {
          return await apiEditTopic({
            data: this.baseForm.tableData
          })
        } else {
          return await apiCreateTopic({
            data: this.baseForm.tableData,
          })
        }
      }).then(() => {
        this.$message.success('保存成功！')
        this.$router.go(-1)
      }).catch(() => {
      })
    })
  }

  addNewOne(img: string) {
    this.baseForm.tableData.push({
      name: '', // 中文名
      name_en: '', // 英文名
      status: 1,
      is_top: 0,
      sort: 1,
      url: img, // 封面图
      gif_models:'',
      gif_models_name:'',
      note: '', // 描述
      work_bench_type: this.$route.params.workBenchType,// 所属模式
      from_origin_topic_name: '',
      models: '',// 3D模型
      models_name: '',
      mobile_models: '', // 手机模型
      mobile_models_name: '' // 手机模型名字
    })
  }

  // 批量上传手机模型成功
  handleMobileSuccess(response: any) {
    const name = response.file.file.name
    const url = response.res.res.requestUrls[0].split('?').shift()
    if (response.file.data) {
      const index = response.file.data.$index
      this.baseForm.tableData[index].mobile_models = url
      this.baseForm.tableData[index].mobile_models_name = name
      return
    }
    this.baseForm.tableData.push({
      name: '', // 中文名
      name_en: '', // 英文名
      status: 1,
      is_top: 0,
      sort: 1,
      url: '', // 封面图
      note: '', // 描述
      work_bench_type: 1,// 所属模式
      from_origin_topic_name: '',
      models: '', // 3D模型
      models_name: '',
      mobile_models: url, // 手机模型
      mobile_models_name: name, // 手机模型名字
      gif_models:'',
      gif_models_name:'',
    })
    // }
  }

  // 批量上传模型成功
  handleModelsSuccess(response: any) {
    const name = response.file.file.name
    const url = response.res.res.requestUrls[0].split('?').shift()
    if (response.file.data) {
      const index = response.file.data.$index
      this.baseForm.tableData[index].models = url
      this.baseForm.tableData[index].models_name = name
      return
    }
    this.baseForm.tableData.push({
      name: '', // 中文名
      name_en: '', // 英文名
      status: 1,
      is_top: 0,
      sort: 1,
      url: '', // 封面图
      note: '', // 描述
      work_bench_type: 1,// 所属模式
      from_origin_topic_name: '',
      models: url, // 3D模型
      models_name: name,
      mobile_models: '', // 手机模型
      mobile_models_name: '', // 手机模型名字
      gif_models: '',
      gif_models_name: '',
    })
    // }
  }

  // 删除当前行
  deleteRow(row: any) {
    this.$confirm('此操作仅删除当前行, 是否继续?', '删除提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    }).then(async () => {
      this.baseForm.tableData.splice(row.$index, 1)
      this.$message({
        type: 'success',
        message: '删除成功!'
      })
    }).catch(() => {
    })
  }

  // 素材封面上传成功
  coverUploadSuccess(response: any) {
    const {index} = response.file.data
    const {requestUrls} = response.res.res
    const {method} = response.file.data
    switch (method) {
      case 'cover':
        this.baseForm.tableData[index].url = requestUrls[0].split('?')[0]
        break
      case 'imgList':
        this.addNewOne(requestUrls[0].split('?')[0])
        break
    }
  }
  
  //主题封面gif图片上传
  coverUploadSuccessGif(response: any) {
    const {index} = response.file.data
    const {requestUrls} = response.res.res
    const {method} = response.file.data
    switch (method) {
      case 'cover':
        this.baseForm.tableData[index].gif_models = requestUrls[0].split('?')[0]
        break
      case 'imgList':
        this.addNewOne(requestUrls[0].split('?')[0])
        break
    }
  }


  // OSS上传
  async uploadFile(file: any) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const OSS = require('ali-oss')
    const client = new OSS({
      accessKeyId: 'LTAI5t6CTbjsxAa1kz4x3RCp',
      accessKeySecret: 'eNlsZparfM5VkW3OSQJ9MkbH0UJH8D',
      // stsToken: '',
      region: 'oss-cn-qingdao',
      bucket: 'imagetru3d'
    })
    // 获取要上传的文件
    const uploadFile = file.file
    // 获取扩展名
    const suffix = file.file.name.split('.').pop()
    const uuid = new Date().getTime() + Math.random().toString(36).substr(2)
    const path = 'uploads/light/' + uuid + '.' + suffix
    const res = await client.multipartUpload(path, uploadFile)
    return {res, file}
  }

  // 取消返回上一层
  async cancelSubmit() {
    // 如果没有数据，直接返回，有数据的话判断是不是编辑，是编辑弹框确认后返回
    if (this.baseForm.tableData.length === 0) {
      this.$router.go(-1)
      return
    } else if (this.$route.params.data) {
      return goBackConfirm(() => {
        this.$router.go(-1)
      })
    }
    // 有数据且不是编辑，提出确认，确认后删除云端文件，返回上一页
    goBackConfirm(async () => {
      let imgUrls = this.baseForm.tableData.map((item: any) => item.url)
      await deleteOSSFile(imgUrls)
      this.$router.go(-1)
    })
  }

  mounted() {
    if (this.$route.params.data) {
      console.log(this.$route.params)
      this.baseForm.tableData = this.$route.params.data
    }
  }

  rowClass() {
    return "text-align:center"
  }
}
